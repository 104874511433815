import React from "react"
import { navigate } from "gatsby"

import { AnimatedButton } from "@components/shared"

import { container__categories__single } from "./styles/blog.module.scss"

const SingleCard = ({ name, posts, slug }) => {
  let categoryImage = posts.nodes[0].featuredImage.node
  return (
    <AnimatedButton
      className={container__categories__single}
      onClick={() => navigate(`/blog/categories/${slug}`)}
    >
      <img src={categoryImage.sourceUrl} loading="lazy" alt="" />
      <h5>{name}</h5>
    </AnimatedButton>
  )
}

export default SingleCard
