import React from "react"
import { Layout } from "@components/layout"
import {Search, Contact } from "@components/standard"
import SingleCard from "./singleCard"

import {
  container,
  container__categories,
} from "./styles/blog.module.scss"

const Blog = ({ pageContext }) => {
  const { posts, page, categories } = pageContext
  console.log('categries', categories)
  const notEmptyCategories = categories.filter(
    (el) => el.posts.nodes.length > 0
  )
  const mainCategories = notEmptyCategories.filter((el) => el.slug === "akademia-programowania" || el.slug === "gdybania-seniora")
  return (
    <Layout {...page}>
      <section className={container}>
        <h1>Blog</h1>
        <p>
          Przeczytaj o Akademii, mentorach, naszych metodach nauczania i kilku
          ciekawych technologicznych rzeczach.{" "}
        </p>
        <Search />
        <div className={container__categories}>
          {mainCategories.map((category, i) => {
            return <SingleCard {...category} />
          })}
        </div>
        <Contact />
      </section>
    </Layout>
  )
}
export default Blog
